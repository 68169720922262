/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.onboarding-step {
  position: relative;
  padding-left: 34px;
  margin-bottom: 8px;
}

.onboarding-step.no-step-number {
  padding-left: 0;
}

.onboarding-step:not(.is-open):not(.is-finished) {
  opacity: 0.4;
}

.onboarding-step .boxed-group-actions {
  height: 24px;
  line-height: 24px;
}

.onboarding-step hr {
  margin-left: -54px;
}

.onboarding-step-number {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  background-color: #b9b9b9;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.onboarding-step.is-open .onboarding-step-number {
  background-color: #236a97;
}

.onboarding-step.is-finished {
  cursor: pointer;
  outline: none;
}

.onboarding-step ol.list-styled {
  list-style: none;
  padding-left: 0;
  counter-reset: step-counter;
}

.onboarding-step .markdown {
  line-height: inherit;
}

.onboarding-step ul.list-styled li {
  margin-top: 8px;
  margin-bottom: 8px;
}

.onboarding-step ol.list-styled > li {
  position: relative;
  counter-increment: li;
  margin-bottom: 16px;
}

.onboarding-step ol.list-styled > li::before {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  direction: rtl;
  text-align: center;
  background-color: #4b9fd5;
  border-radius: 50%;
  content: counter(li);
}
