/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.button.boolean-toggle {
  display: inline-block;
  vertical-align: middle;
  width: 48px;
  height: 24px;
  padding: 1px;
  border: 1px solid #cdcdcd;
  border-radius: 24px;
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.button.boolean-toggle:hover {
  background-color: #fff;
}

.button.boolean-toggle:focus {
  border-color: #4b9fd5;
  background-color: #f6f6f6;
}

.boolean-toggle-handle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border: 1px solid #cdcdcd;
  border-radius: 22px;
  box-sizing: border-box;
  background-color: #f6f6f6;
  transition: transform 0.3s cubic-bezier(0.87, -0.41, 0.19, 1.44), border 0.3s ease;
}

.boolean-toggle-handle > * {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.button.boolean-toggle-on {
  border-color: #236a97;
  background-color: #236a97;
  color: #236a97;
}

.button.boolean-toggle-on:hover {
  background-color: #236a97;
}

.button.boolean-toggle-on:focus {
  background-color: #236a97;
}

.button.boolean-toggle-on .boolean-toggle-handle {
  border-color: #f6f6f6;
  transform: translateX(24px);
}

.button.boolean-toggle-on .boolean-toggle-handle > * {
  opacity: 1;
}
