/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.DayPicker {
  display: inline-block;
}

.DayPicker-wrapper {
  position: relative;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  padding-bottom: 8px;
  flex-direction: row;
}

.DayPicker-Months {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.DayPicker-Month {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  margin: 0 8px;
  margin-top: 8px;
}

.DayPicker-Weekdays {
  display: table-header-group;
}

.DayPicker-WeekdaysRow {
  display: table-row;
}

.DayPicker-Weekday {
  display: table-cell;
  padding: 8px;
  font-size: 12px;
  text-align: center;
  color: #999;
}

.DayPicker-Weekday abbr[title] {
  border-bottom: none;
  text-decoration: none;
}

.DayPicker-Body {
  display: table-row-group;
}

.DayPicker-Week {
  display: table-row;
}

.DayPicker-Day {
  display: table-cell;
  line-height: 15px;
  padding: 8px;
  text-align: center;
  cursor: pointer;
  vertical-align: top;
  outline: none;
}

.DayPicker--interactionDisabled .DayPicker-Day {
  cursor: default;
}

.DayPicker-Footer {
  padding-top: 8px;
}

/* Default modifiers */

.DayPicker-Day--today {
  font-weight: bold;
}

.DayPicker-Day--outside {
  cursor: default;
  color: #8b9898;
}

.DayPicker-Day--disabled {
  color: #cdcdcd;
  cursor: not-allowed;
}

/* Example modifiers */

.DayPicker-Day--sunday {
  background-color: #f7f8f8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
  color: #dce0e0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #4b9fd5;
  color: #fff;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--highlighted):not(.DayPicker-Day--outside):hover {
  background-color: #f3f3f3;
}

.DayPicker-Day--highlighted:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside) {
  background-color: #cae3f2;
}
