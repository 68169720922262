/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.rating {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 24px;
  box-sizing: border-box;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.35);
}

.rating-muted {
  background-color: #bdbdbd !important;
  color: #fff !important;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.35) !important;
}

a > .rating {
  margin-bottom: -1px;
  border-bottom: 1px solid;
  transition: all 0.2s ease;
}

a > .rating:hover {
  opacity: 0.8;
}

.rating-A {
  line-height: 23px;
  background-color: #00aa00;
}

a > .rating-A {
  border-bottom-color: #00aa00;
}

.rating-B {
  background-color: #b0d513;
}

a .rating-B {
  border-bottom-color: #b0d513;
}

.rating-C {
  background-color: #eabe06;
}

a .rating-C {
  border-bottom-color: #eabe06;
}

.rating-D {
  background-color: #ed7d20;
}

a .rating-D {
  border-bottom-color: #ed7d20;
}

.rating-E {
  background-color: #d4333f;
}

a .rating-E {
  border-bottom-color: #d4333f;
}

.rating-small {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin-top: -1px;
  margin-bottom: -1px;
  font-size: 12px;
}
