/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.navbar-tabs {
  display: flex;
  align-items: center;
  clear: left;
  height: 24px;
  margin-top: 8px;
}

.navbar-tabs > li + li {
  margin-left: 20px;
}

.navbar-tabs > li > a {
  display: block;
  height: 24px;
  line-height: 16px;
  padding-top: 2px;
  border-bottom: 3px solid transparent;
  box-sizing: border-box;
  color: #333;
  transition: none;
}

.navbar-tabs > li > a.active,
.navbar-tabs > li > a:hover,
.navbar-tabs > li > a:focus {
  border-bottom-color: #4b9fd5;
}
