/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.sonar-d3 .treemap-container {
  position: relative;
}

.sonar-d3 .treemap-cell {
  position: absolute;
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
  box-sizing: border-box;
  text-align: center;
  overflow: hidden;
}

.sonar-d3 .treemap-cell:focus {
  outline: none;
}

.sonar-d3 .treemap-inner {
  display: inline-flex;
  vertical-align: middle;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 8px;
  box-sizing: border-box;
  line-height: 1.2;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
}

.sonar-d3 .treemap-inner .treemap-icon {
  flex-shrink: 0;
}

.sonar-d3 .treemap-inner .treemap-icon svg {
  margin-top: 2px;
}

.sonar-d3 .treemap-inner .treemap-icon svg path {
  fill: #f3f3f3 !important;
}

.sonar-d3 .treemap-inner .treemap-text {
  flex-shrink: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: #f3f3f3;
}

.sonar-d3 .treemap-inner .treemap-text-suffix {
  color: #e6e6e6;
  font-size: 12px;
}

.sonar-d3 .treemap-link {
  position: absolute;
  z-index: 2;
  top: 5px;
  right: 5px;
  line-height: 14px;
  font-size: 12px;
  border-bottom: none;
}

.sonar-d3 .treemap-link:hover {
  color: #d1eafb;
}

.sonar-d3 .treemap-link i,
.sonar-d3 .treemap-link i:before {
  vertical-align: top;
  font-size: inherit;
  line-height: inherit;
}
