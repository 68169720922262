/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.select-list-container {
  min-width: 500px;
  box-sizing: border-box;
}

.select-list-control {
  margin-bottom: 10px;
  box-sizing: border-box;
}

.select-list-list-container {
  border: 1px solid #bfbfbf;
  box-sizing: border-box;
  height: 400px;
  overflow: auto;
}

.select-list-list-checkbox {
  display: flex !important;
  align-items: center;
}

.select-list-list-checkbox i {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.select-list-list-disabled {
  cursor: not-allowed;
}

.select-list-list-disabled > a {
  pointer-events: none;
}

.select-list-list-item {
  display: inline-block;
  vertical-align: middle;
}
