/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.locations-navigator-file {
  position: relative;
}

.locations-navigator-file + .locations-navigator-file {
  margin-top: 12px;
}

.locations-navigator-file:not(:last-child)::before {
  position: absolute;
  display: block;
  width: 0;
  top: 13px;
  bottom: -16px;
  left: 4px;
  border-left: 1px dotted #d18582;
  content: '';
}

.location-file-locations {
  padding-left: 16px;
}

.location-file {
  height: 16px;
  padding-bottom: 4px;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.location-file-circle,
.location-file-circle-multiple,
.location-file-circle-multiple::before,
.location-file-circle-multiple::after {
  position: relative;
  top: 1px;
  display: inline-block;
  width: 9px;
  height: 9px;
  border: 1px solid #d18582;
  border-radius: 100%;
  box-sizing: border-box;
  background-color: #f2dede;
}

.location-file-circle-multiple {
  top: -2px;
}

.location-file-circle-multiple::before {
  position: absolute;
  z-index: 7;
  top: 2px;
  left: -1px;
  content: '';
}

.location-file-circle-multiple::after {
  position: absolute;
  z-index: 8;
  top: 5px;
  left: -1px;
  content: '';
}

.location-file-more {
  border-color: rgba(209, 133, 130, 0.2);
  color: rgb(209, 133, 130) !important;
  font-style: italic;
  font-weight: normal;
}

.location-file-more:hover,
.location-file-more:focus {
  border-color: rgba(209, 133, 130, 0.6);
}
