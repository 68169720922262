/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.bubble-chart text {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.bubble-chart-bubble {
  fill: #4b9fd5;
  fill-opacity: 0.2;
  stroke: #4b9fd5;
  cursor: pointer;
  transition: fill-opacity 0.2s ease;
}

.bubble-chart-bubble:hover {
  fill-opacity: 0.8;
}

.bubble-chart-grid {
  shape-rendering: crispedges;
  stroke: #eee;
}

.bubble-chart-tick {
  fill: #666;
  font-size: 12px;
  text-anchor: middle;
}

.bubble-chart-tick-y {
  text-anchor: end;
}

.bubble-chart-zoom {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 3;
}
