/*
 * SonarQube
 * Copyright (C) 2009-2022 SonarSource SA
 * mailto:info AT sonarsource DOT com
 *
 * This program is free software; you can redistribute it and/or
 * modify it under the terms of the GNU Lesser General Public
 * License as published by the Free Software Foundation; either
 * version 3 of the License, or (at your option) any later version.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the GNU
 * Lesser General Public License for more details.
 *
 * You should have received a copy of the GNU Lesser General Public License
 * along with this program; if not, write to the Free Software Foundation,
 * Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
 */
.domain-measures-value {
  margin-right: 4px;
}

.domain-measures-value span {
  line-height: 16px;
}

.domain-measures-value .rating {
  margin-left: -4px;
  margin-right: -4px;
}

.search-navigator-facet .leak-box {
  height: 24px;
  line-height: 24px;
  padding: 0 8px;
  margin-top: -1px;
  margin-right: -7px;
  border-radius: 2px;
  box-sizing: border-box;
}

.search-navigator-facet:hover .leak-box,
.search-navigator-facet.active .leak-box {
  height: 22px;
  margin-top: 0;
  margin-right: -6px;
  border-top: none;
  border-bottom: none;
  border-right: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.search-navigator-facet.active .leak-box {
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.domain-measures-header {
  display: inline-block;
  padding: 4px 10px;
  white-space: nowrap;
}

.measure-details-metric {
  display: flex;
  align-items: center;
}

.measure-details-primary {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.measure-details-primary-actions {
  display: flex;
  align-items: center;
}

.measure-details-secondary {
  display: inline-block;
  width: 260px;
  margin-top: 4px;
}

.measure-details-component-row.selected {
  background-color: #cae3f2 !important;
}

.measure-details-component-cell {
  max-width: 0;
}

.measure-details-component-cell > div {
  max-width: 100%;
}

.domain-measures-value .rating,
.measure-details-value .rating {
  width: 18px;
  height: 18px;
  line-height: 18px;
  margin-top: -2px;
  margin-bottom: -2px;
  font-size: 12px;
}

.domain-measures-value .level {
  height: 18px;
  line-height: 18px;
  border-radius: 18px;
  margin-top: -2px;
  margin-bottom: -2px;
  margin-right: -4px;
}

.measure-details-treemap-legend.color-box-legend {
  margin-right: 0;
}

.measure-details-viewer .issue-list {
  /* no math, just a good guess */
  min-width: 600px;
  width: 800px;
}

@media (max-width: 1320px) {
  .measure-details-viewer .issue-list {
    width: calc(60vw - 80px);
  }
}

.measure-view-select {
  width: 102px;
}

.measure-view-select .Select-menu-outer {
  width: 100px;
  border-top-right-radius: 4px;
}

.measure-content-header {
  display: flex;
  align-items: center;
}

.measure-content-header-left {
  flex: 1;
  min-width: 0;
  white-space: nowrap;
}

.measure-content-header-right .page-actions {
  margin-bottom: 0;
}

.measure-content-header-right {
  margin-left: 16px;
  white-space: nowrap;
}

.measure-favorite svg {
  vertical-align: middle;
}

.measure-overview-bubble-chart {
  position: relative;
  border: 1px solid #e6e6e6;
  background-color: #fff;
}

.measure-overview-bubble-chart-content {
  padding: 0;
  padding-left: 60px;
}

.measure-overview-bubble-chart-header {
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e6e6e6;
}

.measure-overview-bubble-chart-title {
  position: absolute;
}

.measure-overview-bubble-chart-legend {
  display: flex;
  flex-direction: column;
  text-align: center;
  flex-grow: 1;
}

.measure-overview-bubble-chart-footer {
  padding: 15px 60px;
  border-top: 1px solid #e6e6e6;
  text-align: center;
  font-size: 12px;
  line-height: 1.4;
}

.measure-overview-bubble-chart-axis {
  color: #666;
  font-size: 12px;
}

.measure-overview-bubble-chart-axis.x {
  position: relative;
  top: -8px;
  padding-bottom: 8px;
  text-align: center;
}

.measure-overview-bubble-chart-axis.y {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: rotate(-90deg) translateX(-50%);
  transform-origin: left;
}
